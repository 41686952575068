<template>
  <main class="view product">

    <!-- <view-header :heading="activeProduct.title">
      <template v-slot:title-column-content>
        <div class="dates" v-if="dates">
          <icon graphic="calendar"></icon>
          <span>{{ dates.start }}–{{ dates.end }}</span>
        </div>
        <p v-if="activeProduct.description" class="lead">{{ _(activeProduct.description) }}</p>
      </template>

      <template v-slot:image-column-content>
        <div class="image-container">
          <image-component v-if="activeProduct.image" :url="activeProduct.image.url" :alt="activeProduct.image.alt"></image-component>
        </div>
      </template>
    </view-header> -->

    <div class="view-inner">
      <section class="breadcrumb">
        <div class="crumb">
          <router-link :to="{ name: 'Home', params: {} }">{{ _('home') | capitalise }}</router-link>
        </div>
        <span class="separator">/</span>
        <div class="crumb">
          {{ _(activeProduct.title) | capitalise }}
        </div>
      </section>

      <!-- <section class="product-content"> -->

        <variants></variants>

        <!-- <section class="product-section courses">
          <h2>{{ _('course_plural') | capitalise }}</h2>
          <div class="items">
            <article class="course" v-for="course in courses" :key="getKey(course)">
              <router-link :to="{ name: 'Course', params: { id: course.id } }">{{ _(course.title) }}</router-link>
            </article>
          </div>
        </section> -->

        <!-- <section class="product-section courses">
          <router-link class="product-section-link"
          v-for="course in courses"
          :key="getKey(course)"
          :to="{ name: 'LessonOverview', params: { productID: product.id, courseID: course.id } }">

            <div class="image-column">
              <div class="image-container">
                <icon graphic="course"></icon>
              </div>
            </div>

            <div class="text-column">

              <div class="inner">
                <icon graphic="bookmark" v-if="getCourseStatus(course.id)"></icon>
                <h3 class="title">{{ _(course.title) }}</h3>
                <p class="description">{{ _(course.description) }}</p>
                <div class="progress">

                  <span class="lesson"
                  v-for="id in course.lessons"
                  :class="{ '-completed': getLessonStatus(course.id, id) }"
                  :key="'course_' + course.id + 'lesson_marker_' + id">
                    <icon v-if="getLessonStatus(course.id, id)" class="marker" graphic="checkmark"></icon>
                    <i v-else class="icon marker"></i>
                  </span>

                  <span class="quiz"
                  v-for="id in course.quizes"
                  :class="{ '-completed': getQuizStatus(course.id, id) }"
                  :key="'course_' + course.id + 'lesson_marker_' + id">
                    <icon class="marker" graphic="quiz-marker"></icon>
                  </span>

                </div>
              </div>

              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>

            </div>

          </router-link>
        </section>

        <section class="product-section instructions">
          <div class="product-section-link">
            <div class="image-column">
              <div class="image-container">
                <icon graphic="instructions"></icon>
              </div>
            </div>
            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('instructions') | capitalise }}</h3>
                <p class="description">How to maintain {{ activeProduct.title }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>
          </div>
        </section>

        <section class="product-section troubleshooting">
          <router-link class="product-section-link"
          :to="{ name: 'Troubleshooting', params: { productID: activeProduct.id } }">

            <div class="image-column">
              <div class="image-container">
                <icon graphic="troubleshooting"></icon>
              </div>
            </div>

            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('troubleshooting') | capitalise }}</h3>
                <p class="description">How to find problems and repair them</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>

          </router-link>
        </section>

        <section class="product-section parts">
          <router-link :to="{ name: 'Parts', params: { productID: activeProduct.id } }" class="product-section-link">
            <div class="image-column">
              <div class="image-container">
                <icon graphic="parts"></icon>
              </div>
            </div>
            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('parts') | capitalise }}</h3>
                <p class="description">Find spare parts for {{ activeProduct.title }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>
          </router-link>
        </section> -->

        <!-- <parts :ids="activeProduct.parts"></parts> -->

      <!-- </section> -->
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// components
import ViewHeader from '@/components/ViewHeader.vue'
import Parts from '@/components/Parts.vue'
import Variants from '@/components/Variants.vue'

// mixins
import TranslationMixin from '@/mixins/translation.js'

export default {
  name: 'Home',
  data: function() {
    return {
      dataLoaded: false
    }
  },
  mixins: [
    TranslationMixin
  ],
  components: {
    ViewHeader,
    Parts,
    Variants,
  },
  computed: {
    activeProduct: function() {
      return this.$store.getters.getActiveProduct
    },
    course_plural: function() {
      return this._('course_plural')
    },
    courses: function() {
      var aTheCourses = [];
      this.activeProduct.courses.forEach((id, i) => {
        aTheCourses.push(this.$store.getters.getCourseByID(id))
      });
      return aTheCourses;
    },
    dates: function() {
      if (this.activeProduct.dates_in_production) {
        var oTheDates = {};
        var dates = this.activeProduct.dates_in_production;
        if (dates.start) {
          oTheDates.start = dates.start;
          oTheDates.end = dates.end || new Date().getFullYear();
          return oTheDates;
        }
        return false
      }
      return false
    }
  },
  methods: {
    getCourseKey: function(id) {
      return 'course_' + id
    },
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    getLessonStatus: function(courseID, lessonID) {
      return this.$store.getters.getLessonStatus(courseID, lessonID);
    },
    getQuizStatus: function(courseID, lessonID) {
      return this.$store.getters.getQuizStatus(courseID, lessonID);
    },
    getCourseStatus: function(id) {
      console.log(this.$store.getters.getCourseStatus(id));
      return this.$store.getters.getCourseStatus(id);
    }
    // coursePassed: function() {
    //   if (this.getQuizStatus) {
    //     return true
    //   }
    // },
  },
  mounted() {
    this.$store.dispatch('setActiveCourse', null);
    // this.$store.dispatch('setActiveProduct', this.$route.params.id).then(result => {
    //   console.log(result);
    //   // this.dataLoaded = true;
    // }).catch(err => {
    //   console.log(err);
    // })
  },
  // beforeRouteLeave (to, from, next) {
  //   this.$store.dispatch('setActiveCourse', null);
  //   next();
  // }
}
</script>

<style lang="scss">
.view.product {
  .product-content {
    @include link-list-items;
    grid-gap: 0;
    >>> .svg-stroke {
      stroke: var(--color-border-grey);
    }
  }
  .product-section {
    &+.product-section {
      border-top: .1rem solid var(--color-border-grey);
    }
    .product-section-link {
      @include product-link-style;
    }
  }
}
</style>
