<template>
  <div class="view product variant">
    <view-header :heading="_(activeVariant.title)">
      <template v-slot:title-column-content>
        <div class="dates" v-if="dates">
          <icon graphic="calendar"></icon>
          <span>{{ dates.start }}–{{ dates.end }}</span>
        </div>
        <p v-if="activeVariant.description && activeVariant.description != 0" class="lead" v-html="_(activeVariant.description)"></p>
        <!-- <p v-if="activeVariant.description" class="lead">{{ _(activeVariant.description) }}</p> -->
      </template>

      <template v-slot:image-column-content>
        <div class="image-container">
          <image-component v-if="activeVariant.image" :url="activeVariant.image.url" :alt="activeVariant.image.alt"></image-component>
        </div>
      </template>
    </view-header>

    <div class="view-inner">
      <section class="breadcrumb">
        <div class="crumb">
          <router-link :to="{ name: 'Home', params: {} }">{{ _('home') | capitalise }}</router-link>
        </div>
        <span class="separator">/</span>
        <div class="crumb">
          <router-link :to="{ name: 'Product', params: { productID: activeProduct.id } }">{{ _(activeProduct.title) }}</router-link>
        </div>
        <span class="separator">/</span>
        <div class="crumb">
          {{ _(activeVariant.title) }}
        </div>
      </section>

      <section class="product-content">

        <section class="product-section courses">
          <router-link class="product-section-link"
          v-for="course in courses"
          :key="getKey(course)"
          :to="{ name: 'LessonOverview', params: { productID: activeProduct.id, variantID: activeVariant.id, courseID: course.id } }">

            <div class="image-column">
              <div class="image-container">
                <icon graphic="course"></icon>
              </div>
            </div>

            <div class="text-column">

              <div class="inner">
                <icon graphic="bookmark" v-if="getCourseStatus(course.id)"></icon>
                <h3 class="title">{{ _(course.title) }}</h3>
                <p class="description" v-html="_(course.description)" v-if="course.description && course.description != 0"></p>
                <div class="progress">

                  <span class="lesson"
                  v-for="id in course.lessons"
                  :class="{ '-completed': getLessonStatus(course.id, id) }"
                  :key="'course_' + course.id + 'lesson_marker_' + id">
                    <icon v-if="getLessonStatus(course.id, id)" class="marker" graphic="checkmark"></icon>
                    <i v-else class="icon marker"></i>
                  </span>

                  <span class="quiz"
                  v-for="id in course.quizes"
                  :class="{ '-completed': getQuizStatus(course.id, id) }"
                  :key="'course_' + course.id + 'lesson_marker_' + id">
                    <icon class="marker" graphic="quiz-marker"></icon>
                  </span>

                </div>
              </div>

              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>

            </div>

          </router-link>
        </section>

        <!-- <section class="product-section instructions">
          <div class="product-section-link">
            <div class="image-column">
              <div class="image-container">
                <icon graphic="instructions"></icon>
              </div>
            </div>
            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('instructions') | capitalise }}</h3>
                <p class="description">How to maintain {{ activeVariant.title }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>
          </div>
        </section> -->

        <section class="product-section troubleshooting">
          <span class="product-section-link -disabled" v-if="!courseComplete">
            <div class="image-column">
              <div class="image-container">
                <icon graphic="troubleshooting"></icon>
              </div>
            </div>

            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('troubleshooting') | capitalise }}</h3>
                <p class="description">{{ _('troubleshooting_description') | capitalise }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>
          </span>

          <router-link v-else class="product-section-link" :disabled="!courseComplete" :class="{'-disabled': !courseComplete }"
          :to="{ name: 'Troubleshooting', params: { productID: activeProduct.id, variantID: activeVariant.id } }">

            <div class="image-column">
              <div class="image-container">
                <icon graphic="troubleshooting"></icon>
              </div>
            </div>

            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('troubleshooting') | capitalise }}</h3>
                <p class="description">{{ _('troubleshooting_description') | capitalise }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>

          </router-link>
        </section>

        <section class="product-section parts">
          <router-link :to="{ name: 'Parts', params: { productID: activeProduct.id, variantID: activeVariant.id } }" class="product-section-link">
            <div class="image-column">
              <div class="image-container">
                <icon graphic="parts"></icon>
              </div>
            </div>
            <div class="text-column">
              <div class="inner">
                <h3 class="title">{{ _('part_plural') | capitalise }}</h3>
                <p class="description">Find spare parts for {{ _(activeVariant.title) }}</p>
              </div>
              <div class="item-icon">
                <icon graphic="chevron"></icon>
              </div>
            </div>
          </router-link>
        </section>

      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// components
import ViewHeader from '@/components/ViewHeader.vue'
import Parts from '@/components/Parts.vue'

// mixins
import TranslationMixin from '@/mixins/translation.js'

export default {
  name: 'Home',
  data: function() {
    return {
      dataLoaded: false
    }
  },
  mixins: [
    TranslationMixin
  ],
  components: {
    ViewHeader,
    Parts,
  },
  computed: {
    activeProduct: function() {
      return this.$store.getters.getActiveProduct
    },
    activeVariant: function() {
      return this.$store.getters.getActiveVariant
    },
    course_plural: function() {
      return this._('course_plural')
    },
    courses: function() {
      var aTheCourses = [];
      if (this.activeVariant.courses) {
        this.activeVariant.courses.forEach((id, i) => {
          aTheCourses.push(this.$store.getters.getCourseByID(id))
        });
      }
      return aTheCourses;
    },
    dates: function() {
      if (this.activeVariant.dates_in_production) {
        var oTheDates = {};
        var dates = this.activeVariant.dates_in_production;
        if (dates.start) {
          oTheDates.start = dates.start;
          oTheDates.end = dates.end || new Date().getFullYear();
          return oTheDates;
        }
        return false
      }
      return false
    },
    courseComplete: function() {
      var isComplete = true;

      this.activeVariant.courses.forEach((id, i) => {
        if (!this.getCourseStatus(id)) {
          isComplete = false;
        }
      });

      return isComplete
    }
  },
  methods: {
    getCourseKey: function(id) {
      return 'course_' + id
    },
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    getLessonStatus: function(courseID, lessonID) {
      return this.$store.getters.getLessonStatus(courseID, lessonID);
    },
    getQuizStatus: function(courseID, lessonID) {
      return this.$store.getters.getQuizStatus(courseID, lessonID);
    },
    getCourseStatus: function(id) {
      // console.log('getCourseStatus', this.$store.getters.getCourseStatus(id));
      return this.$store.getters.getCourseStatus(id);
    }
    // coursePassed: function() {
    //   if (this.getQuizStatus) {
    //     return true
    //   }
    // },
  },
  mounted() {
    this.$store.dispatch('setActiveCourse', null);
    // this.$store.dispatch('setActiveProduct', this.$route.params.id).then(result => {
    //   console.log(result);
    //   // this.dataLoaded = true;
    // }).catch(err => {
    //   console.log(err);
    // })
  },
  // beforeRouteLeave (to, from, next) {
  //   this.$store.dispatch('setActiveCourse', null);
  //   next();
  // }
}
</script>

<style lang="scss">
.view.variant {
  .product-content {
    @include link-list-items;
    grid-gap: 0;
    >>> .svg-stroke {
      stroke: var(--color-border-grey);
    }
  }
  .product-section {
    & + .product-section {
      border-top: .1rem solid var(--color-border-grey);
    }
    .product-section-link {
      @include product-link-style;

      & + .product-section-link {
        border-top: .1rem solid var(--color-border-grey);
      }

      &.-disabled {
        .image-column,
        .text-column {
          opacity: .5;
        }
      }
    }
  }
}
</style>
