<template lang="html">
  <div class="component-list tools">
    <a :href="$route.path" class="tool-btn component-link" v-for="tool in tools" :key="getKey(tool)" @click="openToolModal(tool.id, $event)">
      <div class="image-column">
        <icon :graphic="tool.icon"></icon>
      </div>
      <div class="text-column">
        <div class="inner">
          {{ _(tool.title) | capitalise }}
        </div>
        <div class="item-icon">
          <icon graphic="chevron"></icon>
        </div>
      </div>
    </a>
  </div>
</template>

<script>

import TranslationMixin from '@/mixins/translation.js'
import ModalMixin from '@/mixins/modal.js'

import Modal from '@/components/Modal.vue'

export default {
  name: 'Tools',
  data: function() {
    return {
      activeToolID: null,
    }
  },
  mixins: [ModalMixin, TranslationMixin],
  components: {
    Modal,
  },
  computed: {
    lesson: function() {
      return this.$store.getters.getActiveLesson
    },
    tools: function() {
      if (this.lesson.tools) {
        var aTheTools = [];
        this.lesson.tools.forEach((id, i) => {
          var tool = this.$store.getters.getToolByID(id);
          if (tool) {
            aTheTools.push(tool);
          }
        });
        return aTheTools
      }
      return false
    },
    activeTool: function() {
      return this.tools.find(tool => tool.id === this.activeToolID)
    }
  },
  methods: {
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    // openToolModal: function(id, event) {
    //   event.preventDefault();
    //   this.activeTooltID = id;
    //   this.openModal('modalTool')
    // },
    openToolModal: function(id, event) {
      event.preventDefault();
      this.$store.dispatch('setActiveTool', id).then(() => {
        this.openModal('modalTool')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
