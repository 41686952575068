<template lang="html">
  <nav class="content-nav lesson-nav">
    <div class="nav-item">
      <!-- <router-link v-if="prevLesson"
      class="btn next-button"
      :to="{ name: 'Lesson', params: { lessonID: prevLesson, transitionName: 'slide-right' } }">
        {{ _('previous') | capitalise }}
      </router-link> -->
      <button class="btn prev-button -has-icon" v-if="prevLesson" @click="goToLesson(prevLesson)" type="button" name="prevLesson">
        <icon graphic="arrow"></icon>
        <span>{{ _('previous') | capitalise }}</span>
      </button>
    </div>

    <div class="nav-item">
      <button type="button" name="finishLessonBtn" @click="toggleLesson" class="btn finish-button" :class="{ '-done -has-icon': lessonComplete }">
        <icon graphic="checkmark" v-if="lessonComplete"></icon>
        <span>{{ _('done') | capitalise }}</span>
      </button>
    </div>

    <div class="nav-item">
      <!-- <router-link v-if="nextLesson"
      class="btn next-button"
      :to="{ name: 'Lesson', params: { lessonID: nextLesson, transitionName: 'slide-left' } }">
        {{ _('next') | capitalise }}
      </router-link> -->
      <button v-if="nextLesson"
      type="button"
      name="nextLesson"
      class="btn next-button -has-icon"
      :disabled="nextButtonState"
      @click="goToLesson(nextLesson)"
      >
        <span>{{ _('next') | capitalise }}</span>
        <icon graphic="arrow"></icon>
      </button>

      <button v-else-if="nextQuiz"
      type="button"
      name="nextLesson"
      class="btn next-button"
      @click="startQuiz"
      :disabled="nextButtonState"
      ><span>{{ _('start_quiz') | capitalise }}</span></button>
    </div>
  </nav>
</template>

<script>

import TranslationMixin from '@/mixins/translation.js'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

export default {
  name: 'LessonNav',
  mixins: [TranslationMixin, CourseNavMixin, ActiveItemsMixin],
  computed: {
    // lesson: function() {
    //   // return this.$store.getters.getLessonByID(this.$route.params.lessonID)
    //   return this.$store.getters.getActiveLesson
    //   // return this.$store.getters.getLessonByID(this.activeLessonID)
    // },
    lessonStatus: function() {
      return this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)
    },
    lessonComplete: function() {
      return this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)
    },
    prevLesson: function() {
      var currentIndex = this.activeCourse.lessons.indexOf(this.activeLesson.id);
      if (currentIndex === 0) {
        return false;
      }
      return this.activeCourse.lessons[currentIndex - 1]
    },
    nextLesson: function() {
      var currentIndex = this.activeCourse.lessons.indexOf(this.activeLesson.id);
      if (currentIndex >= this.activeCourse.lessons.length) {
        return false;
      }
      return this.activeCourse.lessons[currentIndex + 1]
    },
    currentIndex: function() {
      return this.activeCourse.lessons.indexOf(this.activeLesson.id);
    },
    nextQuiz: function() {
      if (this.currentIndex + 1 >= this.activeCourse.lessons.length && this.activeCourse.quizes && this.activeCourse.quizes.length) {
        return this.activeCourse.quizes[0];
      }
      return false
    },
    nextButtonState: function() {
      if (this.nextLesson || this.nextQuiz) {
        if (!this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)) {
          return 'disabled'
        }
        return false
      }
      return false
    },
  },
  methods: {
    finishLesson: function() {
      // console.log('dette bør være course ID: ', this.$route.params.id);
      // console.log('dette også: ', this.activeCourse.id);
      // console.log('lesson id: ', this.activeLesson.id);
      // this.$store.dispatch('finishLesson', { id: this.activeLesson.id, complete: true })

      if (this.lessonComplete) {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: false })
      } else {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: true })
      }
    },
    toggleLesson: function() {
      if (this.lessonComplete) {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: false })
      } else {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: true })
      }
    },
    getBLockKey: function(ind) {
      return 'block_' + ind
    },
    goToLesson: function(id) {
      this.navigateToLesson(id, this.activeLesson.id).then(() => {
        window.scrollTo(0, 0);
      })
    },
    startQuiz: function() {
      this.$store.dispatch('setActiveTask', null);
      this.navigateToQuiz(this.nextQuiz)
    },
  }
}
</script>

<style lang="scss">

</style>
