<template lang="html">
  <div class="items">
    <div class="warning" v-for="(warning, index) in warnings" :key="'warning_' + index">

      <div class="image-column">
        <icon :graphic="warning.icon"></icon>
      </div>
      <div class="text-column">
        <div class="inner" v-html="_(warning.text)"></div>
      </div>

    </div>
  </div>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'Warnings',
  props: {
    lessonID: String,
  },
  mixins: [TranslationMixin],
  computed: {
    activeLesson: function() {
      return this.$store.getters.getActiveLesson
    },
    warnings: function() {
      return this.activeLesson.warnings
    },
  },
}
</script>

<style lang="scss" scoped>
  .warnings {
    .items {
      display: grid;
      grid-gap: 1rem;
    }
    .warning {
      display: flex;
      padding: var(--gutter-width);
      background-color: var(--color-blue-5);
      border-top: .2rem solid var(--color-blue-4);
      border-bottom: .2rem solid var(--color-blue-4);
      > .image-column {
        flex: none;
        & + .text-column {
          margin-left: var(--gutter-width);
        }
      }
      > .text-column {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      >>> .svg-fill {
        fill: var(--color-blue-2);
      }
      >>> .svg-stroke {
        stroke: var(--color-blue-2);
      }
    }
  }
</style>
