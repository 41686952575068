<template>
  <div class="component-list parts">
    <a :href="'#' + $route.path" class="part-btn component-link" v-for="part in parts" :key="getKey(part)" @click="openModalPart(part.id, $event)">
      <div class="image-column">
        <icon :graphic="part.icon"></icon>
      </div>
      <div class="text-column">
        <div class="inner">
          {{ _(part.title) | capitalise }}
        </div>
        <div class="item-icon">
          <icon graphic="chevron"></icon>
        </div>
      </div>
    </a>
  </div>

  <!-- <section class="product-section parts">
    <h2>{{ _('part_plural') | capitalise }}</h2>
    <div class="items">
      <a :href="$route.path" class="part" v-for="part in parts" :key="getKey(part)" @click="openModalPart(part.id, $event)">
        {{ _(part.title) | capitalise }}
      </a>
    </div>

  </section> -->
</template>

<script>

import TranslationMixin from '@/mixins/translation.js'
import ModalMixin from '@/mixins/modal.js'
import Modal from '@/components/Modal.vue'

export default {
  name: 'Parts',
  data: function() {
    return {
      activePartID: null,
    }
  },
  mixins: [TranslationMixin, ModalMixin],
  components: {
    modal: Modal
  },
  props: {
    ids: Array,
  },
  computed: {
    parts: function() {
      var aTheParts = [];
      this.ids.forEach((id, i) => {
        aTheParts.push(this.$store.getters.getPartByID(id))
      });
      return aTheParts;
    },
    activePart: function() {
      return this.parts.find(part => part.id === this.activePartID)
    }
  },
  methods: {
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    openModalPart: function(id, event) {
      event.preventDefault();
      this.activePartID = id;
      this.$store.dispatch('setActivePart', id).then(() => {
        this.openModal('modalPart')
      })
    },
    setModalTitle: function() {
      return false
      // if (this.activePart) {
      //   console.log('this.activePart.title', this.activePart.title);
      //   var title = this._(this.activePart.title)
      //   return title;
      // } else {
      //   return 'Modal'
      // }
    },
    setModalKey: function() {
      var key = 'modal_';
      if (this.activePart) {
        key += this.activePart.id;
      }
      return key
    }
  },
  created() {

  },
  mounted() {
    // this.registerModal('modalPart');
  }
}
</script>

<style lang="scss">

</style>
