<template>
  <section class="products variants section-block">
    <h2 class="title">{{ _('variant_plural') | capitalise }}</h2>

    <div class="items">

      <router-link class="item product" v-for="variant in variants" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: variant.id } }" :key="variant.id">
        <div class="image-column">
          <div class="image-container">
            <image-component v-if="variant.image" :url="variant.image.url" alt="et bilde her, altså"></image-component>
          </div>
        </div>
        <div class="text-column">
          <div class="inner">
            <h3 class="title">{{ _(variant.title) }}</h3>
            <p class="description" v-html="_(variant.description)" v-if="variant.description && variant.description != 0"></p>
          </div>
          <div class="item-icon">
            <icon graphic="chevron"></icon>
          </div>
        </div>
      </router-link>

    </div>

  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'

export default {
  name: 'Variants',
  data: function() {
    return {
      dataLoaded: false,
    }
  },
  mixins: [TranslationMixin],
  computed: {
    activeProduct: function() {
      return this.$store.getters.getActiveProduct
    },
    variants: function() {
      var aTheVariants = [];

      console.log('VARIANTS, FUCKER', this.activeProduct.variants);
      this.activeProduct.variants.forEach((id, i) => {
        var oTheVariant = this.$store.getters.getVariantByID(id);
        console.log(oTheVariant);
        if (oTheVariant) {
          aTheVariants.push(oTheVariant)
        }
      });
      return aTheVariants
      // return this.activeProduct.variants
    }
  },
  methods: {
    setItemKey(id) {
      return 'product_' + id
    },
  },
}
</script>

<style lang="scss" scoped>
  .products {
    // padding: 0 var(--gutter-width);
    .items {
      @include link-list-items;
      .product {
        @include link-list-item;
        &+.product {
          border-top: .1rem solid var(--color-border-grey);
        }
        .image-column {
          padding: 1rem;
          @include media-sm {
            padding: 2rem 1rem;
          }
        }
      }
    }
  }
</style>
