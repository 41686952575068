<template lang="html">
  <article class="view lesson" :key="'lesson_' + ($route.params.lessonID || 'overview')">
    <transition :name="subviewTransitionName">

      <section v-if="!activeLesson"
      class="course-overview"
      key="lesson-overview">
        <h2>{{ _(activeVariant.title) }}</h2>
        <p class="description" v-html="_(activeCourse.description)" v-if="activeCourse.description && activeCourse.description != 0"></p>
        <!-- <p class="description">{{ _(activeCourse.description) }}</p> -->
        <button type="button" class="course-button" name="startCourseButton" @click="startCourse"><span>{{ _('start_course') | capitalise }}</span></button>
      </section>

      <section v-else
      class="current-lesson"
      :key="'lesson-content_' + activeLesson.id">

        <header class="lesson-header">
          <icon graphic="lesson"></icon>

          <h2 class="title">
            {{ _(activeLesson.title) }}
          </h2>

          <div class="lesson-pager">
            <span>{{ currentLessonCount }}</span>/<span>{{ totalLessonCount }}</span>
          </div>
        </header>

        <section class="lesson-content section-block">
          <div class="content-block" v-for="(block, index) in activeLesson.content" :key="getBLockKey(index)">
            <video-component v-if="block.layout === 'video'" :url="block.url" controls="controls" :posterImage="block.poster"></video-component>

            <p v-if="block.layout === 'text' && block.text != 0" v-html="_(block.text)"></p>

            <div v-if="block.layout === 'image'" class="image-container">
              <image-component :url="block.url" :alt-attr="block.alt"></image-component>
            </div>
          </div>
        </section>

        <section class="warnings section-block" v-if="warnings">
          <h3 class="title sr-only">{{ _('warning_plural') | capitalise }}</h3>
          <warnings :lessonID="activeLesson.id.toString()"></warnings>
        </section>

        <section class="tools section-block" v-if="tools">
          <h3 class="title">{{ _('tool_plural') | capitalise }}</h3>
          <tools :ids="tools"></tools>
        </section>

        <section class="parts section-block" v-if="parts">
          <h3 class="title">{{ _('part_plural') | capitalise }}</h3>
          <parts :ids="parts"></parts>
        </section>

        <lesson-nav v-if="activeLesson" class="section-block"></lesson-nav>

      </section>
    </transition>
  </article>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import LessonNav from '@/components/LessonNav.vue'
import Parts from '@/components/Parts.vue'
import Tools from '@/components/Tools.vue'
import Warnings from '@/components/Warnings.vue'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

export default {
  name: 'LessonView',
  mixins: [TranslationMixin, CourseNavMixin, ActiveItemsMixin],
  components: {
    LessonNav,
    Parts,
    Tools,
    Warnings,
  },
  computed: {
    tools: function() {
      if (this.activeLesson.tools && this.activeLesson.tools.length) {
        return this.activeLesson.tools
      }
      return false
    },
    parts: function() {
      if (this.activeLesson.parts && this.activeLesson.parts.length) {
        return this.activeLesson.parts
      }
      return false
    },
    warnings: function() {
      if (this.activeLesson.warnings && this.activeLesson.warnings.length) {
        return this.activeLesson.warnings
      }
      return false
    },
    lessonStatus: function() {
      return this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)
    },
    lessonComplete: function() {
      return this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)
    },
    prevLesson: function() {
      var currentIndex = this.activeCourse.lessons.indexOf(this.activeLesson.id);
      if (currentIndex === 0) {
        return false;
      }
      return this.activeCourse.lessons[currentIndex - 1]
    },
    nextLesson: function() {
      var currentIndex = this.activeCourse.lessons.indexOf(this.activeLesson.id);
      if (currentIndex >= this.activeCourse.lessons.length) {
        return false;
      }
      return this.activeCourse.lessons[currentIndex + 1]
    },
    currentIndex: function() {
      return this.activeCourse.lessons.indexOf(this.activeLesson.id);
    },
    currentLessonCount: function() {
      return this.currentIndex + 1
    },
    totalLessonCount: function() {
      return this.activeCourse.lessons.length
    },
    nextQuiz: function() {
      if (this.currentIndex + 1 >= this.activeCourse.lessons.length) {
        return this.activeCourse.quizes[0];
      }
      return false
    },
    nextButtonState: function() {
      if (this.nextLesson) {
        if (!this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeLesson.id)) {
          return 'disabled'
        }
        return false
      }
      return false
    },
    subviewTransitionName: function() {
      return this.$store.getters.getCourseSubviewTransitionName
    },
  },
  methods: {
    startCourse: function() {
      // start course at first unfinished lesson
      var iTheLessonID = this.activeCourse.lessons[0];

      for (var i = 0; i < this.activeCourse.lessons.length; i++) {
        if (!this.$store.getters.getLessonStatus(this.activeCourse.id, this.activeCourse.lessons[i])) {

          iTheLessonID = this.activeCourse.lessons[i];
          break;
        }
      }

      this.navigateToLesson(iTheLessonID).then(result => {
        this.$store.dispatch('setCourseMenuOpen', false)
      })
    },
    finishLesson: function() {
      // console.log('dette bør være course ID: ', this.$route.params.id);
      // console.log('dette også: ', this.activeCourse.id);
      // console.log('lesson id: ', this.activeLesson.id);
      // this.$store.dispatch('finishLesson', { id: this.activeLesson.id, complete: true })

      if (this.lessonComplete) {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: false })
      } else {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: true })
      }
    },
    toggleLesson: function() {
      if (this.lessonComplete) {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: false })
      } else {
        this.$store.dispatch('updateLesson', { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: true })
      }
    },
    getBLockKey: function(ind) {
      return 'block_' + ind
    },
    goToLesson: function(id) {
      // var currentIndex = this.activeCourse.lessons.indexOf(this.activeLesson.id);
      // if (currentIndex < this.activeCourse.lessons.indexOf(id)) {
      //   this.$emit('transition-name', 'slide-left');
      // } else {
      //   this.$emit('transition-name', 'slide-right');
      // }
      this.$store.dispatch('setActiveLesson', id);

    },
    goToQuiz: function() {
      this.$store.dispatch('setActiveQuiz', this.nextQuiz).then(result => {
        console.log(this.activeQuiz);
        this.$store.dispatch('setActiveTask', null);
        return result
      }).then(result => {
        this.$store.dispatch('setCourseMode', { mode: 'quiz', course: this.activeCourse.id });
      })
      // this.$store.dispatch('setActiveQuiz', this.nextQuiz).then(result => {
      //   console.log(this.activeQuiz);
      //   this.$store.dispatch('setActiveTask', null);
      //   return result
      // }).then(result => {
      //   this.$store.dispatch('setCourseMode', { mode: 'quiz', course: this.activeCourse.id });
      //   console.log('active quiz', this.activeQuiz);
      //   console.log('activeTask', this.activeTask);
      //
      // })
    },
  },
  beforeRouteUpdate (to, from, next) {

    // console.log('beforeRouteUpdate');
    // console.log(to.params);
    if (to.params.transitionName) {
      this.$emit('transition-name', to.params.transitionName);
    }
    this.$store.dispatch('setActiveLesson', to.params.lessonID).then(result => {
      // var aTheLessons = store.getters.getCourseByID(to.params.courseID).lessons;
      // console.log('lessons_ ', aTheLessons);
      // store.dispatch('setCourseMenuOpen', false)
      window.scrollTo(0, 0);
      next();
    });
  }
}
</script>

<style lang="scss" scoped>
.view.lesson {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: var(--color-blue-1);

  .current-lesson {
    flex: 1 0 100%;
    display: grid;
    gap: var(--gutter-width);

    >>> .content-nav {
      margin-top: 4rem;
      margin-bottom: 4rem;
      @include media-sm {
        margin-top: 7.5rem;
        margin-bottom: 7.5rem;
      }
    }

    >>> .warnings,
    >>> .tools,
    >>> .parts {
      margin-top: var(--gutter-width);
      > .tools,
      > .parts {
        margin-top: 0;
      }
    }

    .image-container {
      width: 100%;
      // border: .1rem solid var(--color-blue-4);
      // border-radius: var(--border-radius);
      background-color: var(--color-light-grey-3);
      // overflow: hidden;
      display: flex;
      justify-content: center;

      > img {
        max-width: 100%;
        max-height: calc(70vh - var(--navbar-height));
        // object-fit: cover;
      }
    }

    .lesson-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      @include media-sm {
        margin-bottom: 4rem;
      }

      .title,
      .lesson-pager {
        margin-top: .3em;
      }

      .icon {
        flex: none;
        margin-right: 2rem;

        .svg-fill {
          fill: var(--color-blue-1);
        }
      }

      >>> .icon {
        flex: none;
        margin-right: 2rem;

        .svg-fill {
          fill: var(--color-blue-1);
        }
      }

      .title {
        margin: 0 2rem 0 0;
      }
    }

    .lesson-content {
      display: grid;
      grid-row-gap: 1em;

      p {
        margin: 0;
      }
    }
  }
}
</style>
