export default {
  data: function() {
    return {}
  },
  computed: {
    lessonsDone: function() {
      var activeCourse = this.$store.getters.getActiveCourse;
      console.log('lessonsDone', activeCourse);
      if (activeCourse.lessons.length) {
        for (var i = 0; i < activeCourse.lessons.length; i++) {
          if (!this.$store.getters.getLessonStatus(activeCourse.id, activeCourse.lessons[i])) {
            return false
          }
        }
      }
      return true
    },
    isCourseMenuOpen: function() {
      return this.$store.getters.getCourseMenuOpen
    },
  },
  methods: {
    navigateToQuiz: function(to, from = null) {
      return new Promise((resolve, reject) => {
        if (this.lessonsDone) {
          this.$store.dispatch('setActiveQuiz', { to: to, from: from }).then(result => {
            return result
          }).then(result => {
            if (this.$route.name !== 'Quiz') {
              // this.$router.push({ name: 'Quiz', quizID: this.activeQuiz.id });
              this.$router.push({ name: 'Quiz' });
            }
            this.$store.dispatch('setCourseMenuOpen', false)
            this.$store.dispatch('setCourseMode', { mode: 'quiz', course: to });
            resolve(result)
          })
        }
      })
    },
    navigateToTask: function(to, from = null) {
      return new Promise((resolve, reject) => {
        // if (!this.$store.getters.getLessonStatus(this.course.id, this.course.lessons[i])) {}

        this.$store.dispatch('setActiveTask', { to: to, from: from }).then(result => {
          console.log('ACTIVE TASK SET');
          console.log(result);
          resolve()
        });
      })
    },
    navigateToLesson: function(to, from = null) {
      return new Promise((resolve, reject) => {
        var activeCourse = this.$store.getters.getActiveCourse;

        console.log('activeCourse', activeCourse);
        console.log('to', to);
        console.log(activeCourse.lessons.indexOf(to));
        var nextIndex = activeCourse.lessons.indexOf(to);

        var bThePermission = true;

        if (nextIndex === 0 || this.$store.getters.getLessonStatus(activeCourse.id, activeCourse.lessons[nextIndex - 1])) {
          this.$store.dispatch('setActiveLesson', { to: to, from: from }).then(result => {
            console.log('ACTIVE LESSON SET');
            console.log(result);

            if (this.$route.name !== 'Lesson') {
              // this.$router.push({ name: 'Lesson', lessonID: id });
              this.$router.push({ name: 'Lesson' })
            }
            this.$store.dispatch('setCourseMode', { mode: 'lesson', course: to });
            this.$store.dispatch('setCourseMenuOpen', false)
            resolve()
          })
        }
      })
    }
  }
}
